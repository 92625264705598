<template>
  <div class="class-configuration">
    <div class="bg"></div>
    <div class="bd">
      <div class="title">
         <h1>部门配置</h1>
        <!-- <p>注：班级名称格式如 "<b>{{classExample}}</b>"</p> -->
      </div>
      <div class="form">
        <a-form
          :label-col="{ span: 5 }"
          :wrapper-col="{ span:17 }"
        >
          <template v-for="(item,index) in data">
            <a-form-item :label="item.grade" :key="index">
              <a-select
                mode="tags"
                style="width: 100%"
                placeholder="请输入部门名称并回车"
                size="large"
                v-model="item.classes"
                dropdownClassName="hideTheDropdownMenu"
              />
            </a-form-item>
          </template>
          <div class="subutton">
            <a-button
              type="primary"
              size="large"
              @click="save"
            >
              保存并提交
            </a-button>
          </div>
        </a-form>
      </div>
    </div>
    <footer-component />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import footerComponent from '@/components/Footer'
import { all } from '@/business/enums'
import apis from '@/business/apis'
export default {
  components: {
    footerComponent
  },
  data () {
    return {
      data: []
    }
  },
  computed: {
    ...mapState('school', [
      'currentSchool'
    ]),
    ...mapGetters('school', [
      'schoolGrades'
    ])
    // classExample () {
    //   switch (this.currentSchool.type[0]) {
    //     default:
    //     case schoolTypes.primary:
    //       return '一年1班'
    //     case schoolTypes.middle:
    //       return '初一1班'
    //     case schoolTypes.hight:
    //       return '高一1班'
    //   }
    // }
  },
  methods: {
    ...mapActions('school', [
      'loadSchoolTypeList'
    ]),
    async save () {
      const groups = []
      let allClassEmpty = true
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].classes && this.data[i].classes.length) {
          allClassEmpty = false
        }
        this.data[i].classes.forEach(group => {
          groups.push({ grade: this.data[i].grade, name: group, weight: 100 - i })
        })
      }
      if (allClassEmpty) {
        this.$message.info('至少要配置一个部门！')
        return
      }
      if (this.haveSameName()) {
        this.$message.info('部门名称不能重复！')
        return
      }
      const response = await this.$http.post(apis.studentGroup.saveMany, { groups: groups, school_id: this.currentSchool._id })
      if (response.body.success) {
        this.$message.success('保存成功')
        this.$router.replace({ name: 'FileManage' })
      }
    },
    haveSameName () {
      const temp = {}
      for (let i = 0; i < this.data.length; i++) {
        for (let j = 0; j < this.data[i].classes.length; j++) {
          if (temp[this.data[i].classes[j]]) {
            return true
          }
          temp[this.data[i].classes[j]] = 1
        }
      }
      return false
    }
  },
  async created () {
    await this.loadSchoolTypeList()
    this.schoolGrades.forEach(item => {
      if (item === all) return
      this.data.push({ grade: item, classes: [] })
    })
  }
}
</script>
<style lang="less">
.hideTheDropdownMenu {
  display: none;
}
.class-configuration {
  > .bg {
    position: fixed;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #f2f2f2;
    background: url(../assets/images/bg.jpg);
    background-size: cover;
    background-position: center;
  }
  > .bd {
    margin: 80px auto 40px;
    padding: 40px 0 48px;
    width: 600px;
    background-color: #fff;
    box-shadow: 0 0 28px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    .title {
      text-align: center;
      margin-bottom: 20px;
      h1 {
        font-weight: bold;
        font-size: 24px;
        margin:0;
      }
      p{
        margin:4px 0 0;
        font-size: 14px;
        color: #ee0a24;
      }
    }
    .form {
      .ant-select-selection--single {
        height: 48px;
      }
      .ant-select-selection__rendered {
        line-height: 46px;
      }
      .subutton {
        text-align: center;
        padding-top: 12px;
        .ant-btn {
          width: 200px;
          height: 52px;
          font-size: 16px;
          font-weight: bold;
          background: linear-gradient(225deg, #e7b543, #f3cc40);
          box-shadow: 0 0 0 #ffd35b;
          border: none;
        }
      }
    }
  }
}
</style>
